<template>
  <div>
    <a-row>
      <a-col :span="6">
        <a-descriptions :column="1" class="custom-descriptions custom-descriptions-max-7">
          <a-descriptions-item label="租户名称">{{ tenant.name }}</a-descriptions-item>
          <a-descriptions-item label="订单数量">{{ tenant.order_count }}</a-descriptions-item>
          <a-descriptions-item label="厅房数量">{{ tenant.hall_count }}</a-descriptions-item>
          <a-descriptions-item label="员工数量">{{ tenant.staff_count }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="6">
        <a-descriptions :column="1" class="custom-descriptions-max-7 custom-descriptions">
          <a-descriptions-item label="套餐数量">{{ tenant.package_count }}</a-descriptions-item>
          <a-descriptions-item label="产品数量">{{ tenant.product_count }}</a-descriptions-item>
          <a-descriptions-item label="联系人">{{ tenant.contact }}</a-descriptions-item>
          <a-descriptions-item label="联系电话">{{ tenant.phone_number }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="12">
        <a-descriptions :column="1" class="custom-descriptions-max-10 custom-descriptions">
          <a-descriptions-item label="状态">{{ tenant.effective ? '有效': '失效' }}</a-descriptions-item>
          <a-descriptions-item label="开发者ID(AppID)">{{ tenant.app_id }}</a-descriptions-item>
          <a-descriptions-item label="开发者密码(AppSecret)">{{ tenant.app_secret }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-tabs
      :active-key="activeKey"
      @change="changeTab"
    >
      <a-tab-pane
        v-for="item in tabs"
        :key="item.routeName"
        :tab="item.tabName"
      >
        <router-view v-if="activeKey===item.routeName" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { findTenant } from '@/api/tenant'
export default {
  name: 'TenantShowTab',
  data() {
    return {
      activeKey: '',
      tenant: {},
      tabs: [
        { routeName: 'tenant_funeral_homes', tabName: '殡仪馆' },
        { routeName: 'tenant_halls', tabName: '厅房' },
        { routeName: 'tenant_package_managements', tabName: '套餐' },
        { routeName: 'tenant_admin_products', tabName: '产品' },
        { routeName: 'tenant_users', tabName: '员工' }
      ],
      tenantInfoChildrenTabs: [
        'tenant_funeral_homes',
        'tenant_halls',
        'tenant_package_managements',
        'tenant_admin_products',
        'tenant_users'
      ]
    }
  },
  computed: {
    tenantId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    const routeName = this.$route.name
    if (this.tenantInfoChildrenTabs.includes(routeName)) {
      this.activeKey = routeName
    } else {
      this.activeKey = 'tenant_funeral_homes'
    }
    this.fetchData()
  },
  methods: {
    changeTab(activeKey) {
      this.activeKey = activeKey
      this.$router.push({ name: activeKey })
    },
    fetchData() {
      findTenant(this.tenantId).then((res) => {
        if (res.code === 0) {
          this.tenant = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  position: relative;
}
</style>
